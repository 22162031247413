import {
  getQuestionsRequest,
  getQuestionsSuccess,
  getQuestionsFailure,
  saveAnswersRequest,
  saveAnswersSuccess,
  saveAnswersFailure,
  getQuestionnairesRequest,
  getQuestionnairesSuccess,
  getQuestionnairesFailure,
} from "../reducers/questions.reducer";
import axios from "axios";
import { store } from "../store";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getQuestions = async (id) => {
  try {
    store.dispatch(getQuestionsRequest());
    const data = await axios.get(
      `${baseUrl}api/v2/questionnaires/${id}/questions`
    );
    if (data.data.questions) {
      let sortedQuestions = data.data.questions
        .slice()
        .sort((a, b) => a.order - b.order);
      store.dispatch(getQuestionsSuccess(sortedQuestions));
    }
  } catch (error) {
    store.dispatch(getQuestionsFailure());
  }
};

export const getQuestionnairres = async (professionId) => {
  try {
    store.dispatch(getQuestionnairesRequest());
    const data = await axios.get(
      `${baseUrl}api/v2/professions/${professionId}/questionnaires`
    );
    if (data.data.questionnaires && data.data.questionnaires.length > 0) {
      store.dispatch(getQuestionnairesSuccess(data.data.questionnaires));
      return data.data.questionnaires;
    }
  } catch (error) {
    store.dispatch(getQuestionnairesFailure());
  }
};

export const saveAnswers = async (params, config) => {
  try {
    store.dispatch(saveAnswersRequest());
    const data = await axios.post(
      `${baseUrl}api/v2/leads/answers`,
      params,
      config
    );
    store.dispatch(saveAnswersSuccess());
  } catch (error) {
    store.dispatch(saveAnswersFailure());
  }
};
