import { createSlice } from "@reduxjs/toolkit";
import { getQuestions } from "../services/questions.service";

const initialState = {
  questions: [],
  questionnaires: [],
  isLoading: false,
  questionnairesIsLoading:false,
  success: false,
  type:''
};

export const questionsSlice = createSlice({
  name: "questionsSlice",
  initialState,
  reducers: {
    getQuestionsRequest(state) {
      return { ...state, isLoading: true };
    },
    getQuestionsSuccess(state, { payload }) {
      return { ...state, isLoading: false, success: true, questions: payload };
    },
    getQuestionsFailure(state) {
      return { ...state, success: false, isLoading: false, questions: [] };
    },
    saveAnswersRequest(state) {
      return { ...state, isLoading: true };
    },
    saveAnswersSuccess(state) {
      return { ...state, isLoading: false, success: true };
    },
    saveAnswersFailure(state) {
      return { ...state, success: false };
    },
    getQuestionnairesRequest(state) {
      return { ...state, questionnairesIsLoading: true,type:'getQuestionnairesRequest',questions:[]};
    },
    getQuestionnairesSuccess(state, { payload }) {
      return {
        ...state,
        questionnairesIsLoading: false,
        success: true,
        questionnaires: payload,
        type:'getQuestionnairesSuccess'
        
      };
    },
    getQuestionnairesFailure(state) {
      return { ...state, success: false, questionnairesIsLoading: false, type:'getQuestionnairesFailure' };
    },
  },
});

// Action creators are generated for each case reducer  function
export const {
  getQuestionsRequest,
  getQuestionsSuccess,
  getQuestionsFailure,
  saveAnswersRequest,
  saveAnswersSuccess,
  saveAnswersFailure,
  getQuestionnairesRequest,
  getQuestionnairesSuccess,
  getQuestionnairesFailure,
} = questionsSlice.actions;
export default questionsSlice.reducer;
